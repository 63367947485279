<template>
  <div class="ranking-board">
    <div class="ranking-board-title">测试记录数量</div>
    <dv-scroll-ranking-board :config="config" class="ranking-board-content" />
  </div>
</template>

<script>

export default {
  name: 'RankingBoard',
  data() {
    return {
      config: {}
    }
  },
  mounted() {
    this.config = {
      data: [
        {
          name: '所有设备',
          value: 0
        },
        {
          name: '已出货设备',
          value: 0
        },
        {
          name: '激活设备',
          value: 0
        },
        {
          name: '测试1',
          value: 0
        },
        {
          name: '测试2',
          value: 0
        },
        {
          name: '测试3',
          value: 0
        },
        {
          name: '测试4',
          value: 0
        },
        {
          name: '测试5',
          value: 0
        },
        {
          name: '测试6',
          value: 0
        },
        {
          name: '测试7',
          value: 0
        },
        {
          name: '测试7',
          value: 0
        }
      ],
      rowNum: 8
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="less">
.ranking-board {
  width: 20%;
  height: 90%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0 30px;

  .ranking-board-title {
    font-weight: bold;
    height: 10%;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .ranking-board-content{
    height: 600px;
  }
}
</style>
